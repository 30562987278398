import clsx from 'clsx'
import * as material from 'material-colors'
import { FC } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { CirclePicker } from 'react-color'
import { useTranslation } from 'react-i18next'
import { Deck } from '../../api/model'
import ColorPicker from '../ColorPicker/ColorPicker'
import classes from './EditDeckModal.module.css'

interface EditDeckModalProps {
  showEditModal: boolean
  deckIdxSelected: number | undefined
  currentFlashcardsDeck: Deck | undefined
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  onCancelCreateDeck: () => void
  duplicatedDeckName: boolean
  setDuplicatedDeckName: (duplicated: boolean) => void
  deckName: string | undefined
  setDeckName: (name: string | undefined) => void
  deckColor: string | undefined
  setDeckColor: (color: string) => void
  showColorPicker: boolean
  setShowColorPicker: (show: boolean) => void
}

const EditDeckModal: FC<EditDeckModalProps> = ({
  showEditModal,
  deckIdxSelected,
  currentFlashcardsDeck,
  onSubmit,
  onCancelCreateDeck,
  duplicatedDeckName,
  deckName,
  setDeckName,
  deckColor,
  setDeckColor,
  showColorPicker,
  setShowColorPicker,
}) => {
  const { t } = useTranslation()

  return (
    <Modal show={showEditModal} onHide={onCancelCreateDeck} centered>
      <Modal.Header>
        <Modal.Title>
          {deckIdxSelected !== undefined
            ? t('flashcards.editFlashcardDeck')
            : t('flashcards.createFlashcardDeck')}
        </Modal.Title>
      </Modal.Header>
      <Form onSubmit={onSubmit}>
        <Modal.Body>
        <Form.Group controlId="deck-name">
            <Form.Label>{t('common.name')}</Form.Label>
            <Form.Control
              type="text"
              value={deckName ?? currentFlashcardsDeck?.name}
              onChange={(e) => {
                setDeckName(e.target.value)
              }}
            />
            {duplicatedDeckName && (
              <Form.Text className="text-danger">
                {t('flashcards.errors.duplicatedDeckName')}
              </Form.Text>
            )}
          </Form.Group>
          <div className="d-flex align-items-center mt-4 mb-2">
            <div>{t('common.color')}</div>
            <Button
              variant="white"
              className={clsx(classes.deckColorPickerContainer)}
              onClick={() => setShowColorPicker(!showColorPicker)}
            >
              <div
                className={clsx(classes.circle)}
                style={{
                  backgroundColor: deckColor,
                  width: 15,
                  height: 15,
                  border: '0.5px solid rgb(33, 37, 41)',
                }}
              ></div>
            </Button>
          </div>
          {showColorPicker && (
            <ColorPicker
              colorPickerComponent={
                <CirclePicker
                  show={showColorPicker}
                  className={clsx(classes.colorPicker)}
                  color={deckColor}
                  colors={[
                    material.red['500'],
                    material.pink['500'],
                    material.purple['500'],
                    material.lightBlue['500'],
                    material.teal['500'],
                    material.lightGreen['500'],
                    material.yellow['500'],
                    material.orange['500'],
                  ]}
                  onChangeComplete={(color: {
                    rgb: { r: number; g: number; b: number; a: number }
                  }) => {
                    const rgbColor = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`
                    setDeckColor(rgbColor)
                  }}
                />
              }
              show={showColorPicker}
              onClose={() => setShowColorPicker(false)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => onCancelCreateDeck()}>
            {t('common.cancel')}
          </Button>
          <Button variant="success" type="submit">
            {t('common.save')}
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default EditDeckModal
