import clsx from 'clsx'
import { FC, Fragment, MouseEventHandler, forwardRef, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Dropdown from 'react-bootstrap/Dropdown'
import Spinner from 'react-bootstrap/Spinner'
import Stack from 'react-bootstrap/Stack'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { UserInfo200 } from '../../api/model/userInfo200'
import { UserIcon } from '../../assets/icons'
import discourseTutotialImg from '../../assets/images/discourse-tutorial.jpeg'
import { useNotifications } from '../../context/NotificationContext'
import { NavItem } from '../../molecules'
import externalLinkItems from '../../utils/externalLinkItems'
import navItems from '../../utils/navItems'
import classes from './SidebarItems.module.css'
import { createPortal } from 'react-dom'

const CustomToggle = forwardRef<
  any,
  { onClick: MouseEventHandler<HTMLDivElement> }
>(({ onClick }, ref) => (
  <div ref={ref} onClick={onClick} className="d-flex justify-content-center">
    <NavItem icon={UserIcon} description="navigation.user" hasTooltip={false} />
  </div>
))

interface SidebarItemsProps {
  userInfo?: UserInfo200
  isLoading: boolean
}

const SidebarItems: FC<SidebarItemsProps> = ({ userInfo, isLoading }) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { setNotification } = useNotifications()
  const [showDiscourseModal, setShowDiscourseModal] = useState(false)
  const [forumUrl, setForumUrl] = useState<string | undefined>(undefined)
  const location = useLocation()
  const navigate = useNavigate()
  const [, , removeCookie] = useCookies([
    'token',
    'seen-promotional-campaigns',
    'seeTutorialLater',
  ])

  const handleLogout = () => {
    queryClient.clear()
    removeCookie('token')
    removeCookie('seen-promotional-campaigns')
    removeCookie('seeTutorialLater')
    console.log('Logout')
  }

  const onForumClick = (forumUrl: string) => {
    if (userInfo?.is_premium) {
      setForumUrl(forumUrl)
      setShowDiscourseModal(true)
    } else {
      setNotification({
        message: t('forum.errors.nonPremium'),
        title: t('forum.errors.title'),
        variant: 'danger',
        timeout: 5000,
      })
    }
  }

  return (
    <>
      <Stack
        className={clsx(
          'h-100',
          classes.sidebarItemsContainer,
          'justify-content-between',
        )}
      >
        <Stack>
          {navItems.map(({ slug, description, to, icon }) => (
            <Fragment key={slug}>
              <div className={classes.sideBarItems}>
                <Link
                  to={to}
                  className={`d-flex align-items-center justify-content-center mb-2 mt-2`}
                >
                  <NavItem
                    description={description}
                    icon={icon}
                    selected={location.pathname.includes(to)}
                  />
                </Link>
              </div>
            </Fragment>
          ))}
          {externalLinkItems.map(
            ({ slug, description, to, icon, className }) => (
              <Fragment key={slug}>
                <div className={className}>
                  <div className={classes.sideBarItems}>
                    {slug !== 'nav-forum' ? (
                      <a
                        href={to}
                        target="_blank"
                        className={
                          'd-flex align-items-center justify-content-center mb-2 mt-2'
                        }
                      >
                        <NavItem
                          description={description}
                          icon={icon}
                          selected={location.pathname.includes(to)}
                          className={classes.sideBarItems}
                        />
                      </a>
                    ) : (
                      <div
                        onClick={() => onForumClick(to)}
                        className={
                          'd-flex align-items-center justify-content-center mb-2 mt-2'
                        }
                      >
                        <NavItem
                          description={description}
                          icon={icon}
                          selected={location.pathname.includes(to)}
                          className={classes.sideBarItems}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            ),
          )}
        </Stack>

        <div className="accountMenu" data-testid="accountMenu">
          <Dropdown
            className={clsx(classes.accountDropdown, 'd-inline')}
            drop="end"
          >
            <Dropdown.Toggle as={CustomToggle} id="user-dropdown" />
            {
              createPortal(
                <Dropdown.Menu className={classes.accountDropdownMenu}>
                  <Dropdown.Header>
                    {isLoading ? (
                      <Spinner animation="border" variant="success" size="sm" />
                    ) : (
                      userInfo?.first_name
                    )}
                  </Dropdown.Header>
                  <Dropdown.Item
                    disabled={userInfo?.is_paying}
                    onClick={() => navigate('/plans')}
                  >
                    {userInfo?.is_paying
                      ? t('account.paymentValidation')
                      : t('user.account')}
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item onClick={handleLogout} data-testid="logout">
                    {t('user.logout')}
                  </Dropdown.Item>
                </Dropdown.Menu>,
                document.body
              )
            }

          </Dropdown>
        </div>
      </Stack>
      <Modal
        show={showDiscourseModal}
        onHide={() => setShowDiscourseModal(false)}
        centered
      >
        <Modal.Header>
          <Modal.Title>{t('forum.modalTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className={classes.discourseBodyText}>
            <p className="mb-4">{t('forum.welcomeText')}</p>
            <img
              src={discourseTutotialImg}
              className={classes.discourseTutorialImg}
            />
            <p className="mt-4">{t('forum.tutorialText')}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => setShowDiscourseModal(false)}>
            {t('common.cancel')}
          </Button>
          <Button
            variant="success"
            onClick={() => window.open(forumUrl, '_blank')}
          >
            {t('common.goToForum')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default SidebarItems
