import clsx from 'clsx'
import { ComponentType, SVGAttributes, useRef, useState } from 'react'
import Overlay from 'react-bootstrap/Overlay'
import Tooltip from 'react-bootstrap/Tooltip'
import { useTranslation } from 'react-i18next'
import classes from './NavItem.module.css'

export interface IconProps extends SVGAttributes<SVGElement> {
  color?: string
  size: number
  ref?: any
}

export interface NavItemProps {
  selected?: boolean
  description: string
  smallScreen?: boolean
  icon: ComponentType<IconProps>
  hasTooltip?: boolean
  size?: number
  notifications?: number
  className?: string
}

const NavItem = ({
  icon: Icon,
  selected = false,
  description,
  smallScreen = false,
  hasTooltip = true,
  size = 30,
  notifications,
  className,
}: NavItemProps) => {
  const { t } = useTranslation()
  const [hover, setHover] = useState(false)
  const target = useRef(null)

  return (
    <div className={'d-inline-block'}>
      <div
        className={clsx(
          'd-flex',
          'flex-column',
          'justify-content-center',
          'align-items-center',
          'position-relative',
          classes.navItemContainer,
        )}
      >
        <Icon
          ref={target}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          size={size}
          color={`${selected ? 'green' : 'white'}`}
          className={className}
        />
        {notifications && (
          <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            {notifications}
          </span>
        )}
        {!smallScreen ? (
          hasTooltip && (
            <Overlay target={target.current} show={hover} placement="right">
              {(props) => (
                <Tooltip id="nav-item-tooltip" {...props}>
                  {t(description)}
                </Tooltip>
              )}
            </Overlay>
          )
        ) : (
          <p
            className={clsx(
              'text-center',
              'd-none',
              'd-lg-block',
              'mb-0',
              classes.resetLink,
              {
                'text-success': selected,
                'text-white': !selected,
              },
            )}
          >
            {t(description)}
          </p>
        )}
      </div>
    </div>
  )
}

export default NavItem
