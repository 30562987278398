import { AxiosError } from 'axios'
import { useCookies } from 'react-cookie'
import { QueryClient } from 'react-query'
import { Navigate } from 'react-router-dom'

const useQueryClient = () => {
  const [, , rmCookie] = useCookies(['token', 'seen-promotional-campaigns'])
  return new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        onError: (err) => {
          const { status = 0 } = (err as AxiosError)?.response || {}
          if (status === 401 || status === 403) {
            rmCookie('token')
            rmCookie('seen-promotional-campaigns')
            return <Navigate to={'/login'} replace />
          }
        },
        retry: false,
      },
    },
  })
}

export default useQueryClient
