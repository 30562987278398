import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const History = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    ref={ref}
    fill={color}
    viewBox="0 0 34.009 29.151"
  >
    <path
      id="Icon_material-history"
      data-name="Icon material-history"
      d="M20.934,4.5A14.577,14.577,0,0,0,6.358,19.075H1.5l6.3,6.3.113.227,6.543-6.527H9.6a11.391,11.391,0,1,1,3.336,8l-2.3,2.3A14.572,14.572,0,1,0,20.934,4.5Zm-1.619,8.1v8.1l6.931,4.113,1.166-1.96L21.744,19.48V12.6Z"
      transform="translate(-1.5 -4.5)"
      fill={color}
    />
  </svg>
))

export default History
