import {
  useState,
  useEffect,
  useRef,
  FC,
  ReactNode,
  CSSProperties,
} from 'react'

interface ColorPickerProps {
  colorPickerComponent: ReactNode
  show?: boolean
  style?: CSSProperties
  onClose: () => void
}

const ColorPicker: FC<ColorPickerProps> = ({
  colorPickerComponent,
  show = false,
  style,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClose()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [show])

  return (
    <div ref={ref} style={style}>
      {colorPickerComponent}
    </div>
  )
}

export default ColorPicker
