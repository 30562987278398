/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  Deck,
  DecksList200,
  FavoriteFlashcardBody,
  Flashcard,
  FlashcardCollection,
  FlashcardCollectionImport,
  FlashcardCreateBody,
  FlashcardTag,
  FlashcardUpdateBody,
  TagsList200
} from '.././model'
import { instance } from '.././instance';



/**
 * flashcard create
 * @summary Create flashcard
 */
export const flashcardCreate = (
    flashcardCreateBody: FlashcardCreateBody,
 ) => {
      
      
      return instance<Flashcard>(
      {url: `/flashcards/flashcard`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flashcardCreateBody
    },
      );
    }
  


export const getFlashcardCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCreate>>, TError,{data: FlashcardCreateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardCreate>>, TError,{data: FlashcardCreateBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardCreate>>, {data: FlashcardCreateBody}> = (props) => {
          const {data} = props ?? {};

          return  flashcardCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardCreateMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardCreate>>>
    export type FlashcardCreateMutationBody = FlashcardCreateBody
    export type FlashcardCreateMutationError = unknown

    /**
 * @summary Create flashcard
 */
export const useFlashcardCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCreate>>, TError,{data: FlashcardCreateBody}, TContext>, }
) => {

      const mutationOptions = getFlashcardCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * collections
 * @summary Retrieve all collections from the notepad for the user
 */
export const flashcardsCollectionsList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<FlashcardCollection[]>(
      {url: `/flashcards/collections/list`, method: 'GET', signal
    },
      );
    }
  

export const getFlashcardsCollectionsListQueryKey = () => {
    return [`/flashcards/collections/list`] as const;
    }

    
export const getFlashcardsCollectionsListQueryOptions = <TData = Awaited<ReturnType<typeof flashcardsCollectionsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof flashcardsCollectionsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFlashcardsCollectionsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof flashcardsCollectionsList>>> = ({ signal }) => flashcardsCollectionsList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof flashcardsCollectionsList>>, TError, TData> & { queryKey: QueryKey }
}

export type FlashcardsCollectionsListQueryResult = NonNullable<Awaited<ReturnType<typeof flashcardsCollectionsList>>>
export type FlashcardsCollectionsListQueryError = unknown

/**
 * @summary Retrieve all collections from the notepad for the user
 */
export const useFlashcardsCollectionsList = <TData = Awaited<ReturnType<typeof flashcardsCollectionsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof flashcardsCollectionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getFlashcardsCollectionsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get flashcard collection by id
 */
export const getFlashcardCollection = (
    collectionId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<FlashcardCollection>(
      {url: `/flashcards/collections/${collectionId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetFlashcardCollectionQueryKey = (collectionId: string,) => {
    return [`/flashcards/collections/${collectionId}`] as const;
    }

    
export const getGetFlashcardCollectionQueryOptions = <TData = Awaited<ReturnType<typeof getFlashcardCollection>>, TError = unknown>(collectionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFlashcardCollection>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetFlashcardCollectionQueryKey(collectionId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getFlashcardCollection>>> = ({ signal }) => getFlashcardCollection(collectionId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(collectionId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getFlashcardCollection>>, TError, TData> & { queryKey: QueryKey }
}

export type GetFlashcardCollectionQueryResult = NonNullable<Awaited<ReturnType<typeof getFlashcardCollection>>>
export type GetFlashcardCollectionQueryError = unknown

export const useGetFlashcardCollection = <TData = Awaited<ReturnType<typeof getFlashcardCollection>>, TError = unknown>(
 collectionId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getFlashcardCollection>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetFlashcardCollectionQueryOptions(collectionId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * flashcard collection update
 * @summary Edit collection
 */
export const flashcardCollectionUpdate = (
    collectionId: string,
    flashcardCollection: FlashcardCollection,
 ) => {
      
      
      return instance<FlashcardCollection>(
      {url: `/flashcards/collections/${collectionId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: flashcardCollection
    },
      );
    }
  


export const getFlashcardCollectionUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionUpdate>>, TError,{collectionId: string;data: FlashcardCollection}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionUpdate>>, TError,{collectionId: string;data: FlashcardCollection}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardCollectionUpdate>>, {collectionId: string;data: FlashcardCollection}> = (props) => {
          const {collectionId,data} = props ?? {};

          return  flashcardCollectionUpdate(collectionId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardCollectionUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardCollectionUpdate>>>
    export type FlashcardCollectionUpdateMutationBody = FlashcardCollection
    export type FlashcardCollectionUpdateMutationError = unknown

    /**
 * @summary Edit collection
 */
export const useFlashcardCollectionUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionUpdate>>, TError,{collectionId: string;data: FlashcardCollection}, TContext>, }
) => {

      const mutationOptions = getFlashcardCollectionUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete flashcard collection
 */
export const deleteFlashcardCollection = (
    collectionId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/flashcards/collections/${collectionId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteFlashcardCollectionMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardCollection>>, TError,{collectionId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardCollection>>, TError,{collectionId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFlashcardCollection>>, {collectionId: string}> = (props) => {
          const {collectionId} = props ?? {};

          return  deleteFlashcardCollection(collectionId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFlashcardCollectionMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFlashcardCollection>>>
    
    export type DeleteFlashcardCollectionMutationError = unknown

    export const useDeleteFlashcardCollection = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardCollection>>, TError,{collectionId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteFlashcardCollectionMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * flashcard collection create
 * @summary Create flashcard collection
 */
export const flashcardCollectionCreate = (
    flashcardCollection: FlashcardCollection,
 ) => {
      
      
      return instance<FlashcardCollection>(
      {url: `/flashcards/collections/create`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flashcardCollection
    },
      );
    }
  


export const getFlashcardCollectionCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionCreate>>, TError,{data: FlashcardCollection}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionCreate>>, TError,{data: FlashcardCollection}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardCollectionCreate>>, {data: FlashcardCollection}> = (props) => {
          const {data} = props ?? {};

          return  flashcardCollectionCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardCollectionCreateMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardCollectionCreate>>>
    export type FlashcardCollectionCreateMutationBody = FlashcardCollection
    export type FlashcardCollectionCreateMutationError = unknown

    /**
 * @summary Create flashcard collection
 */
export const useFlashcardCollectionCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionCreate>>, TError,{data: FlashcardCollection}, TContext>, }
) => {

      const mutationOptions = getFlashcardCollectionCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * flashcard collection import
 * @summary Import flashcard collection
 */
export const flashcardCollectionImport = (
    flashcardCollectionImport: FlashcardCollectionImport,
 ) => {
      
      
      return instance<FlashcardCollection>(
      {url: `/flashcards/collections/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flashcardCollectionImport
    },
      );
    }
  


export const getFlashcardCollectionImportMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionImport>>, TError,{data: FlashcardCollectionImport}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionImport>>, TError,{data: FlashcardCollectionImport}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardCollectionImport>>, {data: FlashcardCollectionImport}> = (props) => {
          const {data} = props ?? {};

          return  flashcardCollectionImport(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardCollectionImportMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardCollectionImport>>>
    export type FlashcardCollectionImportMutationBody = FlashcardCollectionImport
    export type FlashcardCollectionImportMutationError = unknown

    /**
 * @summary Import flashcard collection
 */
export const useFlashcardCollectionImport = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionImport>>, TError,{data: FlashcardCollectionImport}, TContext>, }
) => {

      const mutationOptions = getFlashcardCollectionImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * flashcard collection template import
 * @summary Import template flashcard collection
 */
export const flashcardCollectionTemplateImport = (
    flashcardCollectionImport: FlashcardCollectionImport,
 ) => {
      
      
      return instance<FlashcardCollection>(
      {url: `/flashcards/collections/template/import`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flashcardCollectionImport
    },
      );
    }
  


export const getFlashcardCollectionTemplateImportMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionTemplateImport>>, TError,{data: FlashcardCollectionImport}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionTemplateImport>>, TError,{data: FlashcardCollectionImport}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardCollectionTemplateImport>>, {data: FlashcardCollectionImport}> = (props) => {
          const {data} = props ?? {};

          return  flashcardCollectionTemplateImport(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardCollectionTemplateImportMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardCollectionTemplateImport>>>
    export type FlashcardCollectionTemplateImportMutationBody = FlashcardCollectionImport
    export type FlashcardCollectionTemplateImportMutationError = unknown

    /**
 * @summary Import template flashcard collection
 */
export const useFlashcardCollectionTemplateImport = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardCollectionTemplateImport>>, TError,{data: FlashcardCollectionImport}, TContext>, }
) => {

      const mutationOptions = getFlashcardCollectionTemplateImportMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * flashcard update
 * @summary Edit flashcard
 */
export const flashcardUpdate = (
    flashcardId: string,
    flashcardUpdateBody: FlashcardUpdateBody,
 ) => {
      
      
      return instance<Flashcard>(
      {url: `/flashcards/flashcard/${flashcardId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: flashcardUpdateBody
    },
      );
    }
  


export const getFlashcardUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardUpdate>>, TError,{flashcardId: string;data: FlashcardUpdateBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof flashcardUpdate>>, TError,{flashcardId: string;data: FlashcardUpdateBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof flashcardUpdate>>, {flashcardId: string;data: FlashcardUpdateBody}> = (props) => {
          const {flashcardId,data} = props ?? {};

          return  flashcardUpdate(flashcardId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FlashcardUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof flashcardUpdate>>>
    export type FlashcardUpdateMutationBody = FlashcardUpdateBody
    export type FlashcardUpdateMutationError = unknown

    /**
 * @summary Edit flashcard
 */
export const useFlashcardUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof flashcardUpdate>>, TError,{flashcardId: string;data: FlashcardUpdateBody}, TContext>, }
) => {

      const mutationOptions = getFlashcardUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete flashcard
 */
export const deleteFlashcard = (
    flashcardId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/flashcards/flashcard/${flashcardId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteFlashcardMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcard>>, TError,{flashcardId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcard>>, TError,{flashcardId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFlashcard>>, {flashcardId: string}> = (props) => {
          const {flashcardId} = props ?? {};

          return  deleteFlashcard(flashcardId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFlashcardMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFlashcard>>>
    
    export type DeleteFlashcardMutationError = unknown

    export const useDeleteFlashcard = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcard>>, TError,{flashcardId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteFlashcardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Favorite flashcard
 */
export const favoriteFlashcard = (
    flashcardId: string,
    favoriteFlashcardBody: FavoriteFlashcardBody,
 ) => {
      
      
      return instance<Flashcard>(
      {url: `/flashcards/flashcard/${flashcardId}/favorite`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: favoriteFlashcardBody
    },
      );
    }
  


export const getFavoriteFlashcardMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof favoriteFlashcard>>, TError,{flashcardId: string;data: FavoriteFlashcardBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof favoriteFlashcard>>, TError,{flashcardId: string;data: FavoriteFlashcardBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof favoriteFlashcard>>, {flashcardId: string;data: FavoriteFlashcardBody}> = (props) => {
          const {flashcardId,data} = props ?? {};

          return  favoriteFlashcard(flashcardId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FavoriteFlashcardMutationResult = NonNullable<Awaited<ReturnType<typeof favoriteFlashcard>>>
    export type FavoriteFlashcardMutationBody = FavoriteFlashcardBody
    export type FavoriteFlashcardMutationError = unknown

    export const useFavoriteFlashcard = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof favoriteFlashcard>>, TError,{flashcardId: string;data: FavoriteFlashcardBody}, TContext>, }
) => {

      const mutationOptions = getFavoriteFlashcardMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * decks
 * @summary Retrieve all decks from the user
 */
export const decksList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<DecksList200>(
      {url: `/flashcards/decks/list`, method: 'GET', signal
    },
      );
    }
  

export const getDecksListQueryKey = () => {
    return [`/flashcards/decks/list`] as const;
    }

    
export const getDecksListQueryOptions = <TData = Awaited<ReturnType<typeof decksList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof decksList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDecksListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof decksList>>> = ({ signal }) => decksList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof decksList>>, TError, TData> & { queryKey: QueryKey }
}

export type DecksListQueryResult = NonNullable<Awaited<ReturnType<typeof decksList>>>
export type DecksListQueryError = unknown

/**
 * @summary Retrieve all decks from the user
 */
export const useDecksList = <TData = Awaited<ReturnType<typeof decksList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof decksList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDecksListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * deck create
 * @summary Create deck
 */
export const deckCreate = (
    deck: Deck,
 ) => {
      
      
      return instance<Deck>(
      {url: `/flashcards/decks`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: deck
    },
      );
    }
  


export const getDeckCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deckCreate>>, TError,{data: Deck}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deckCreate>>, TError,{data: Deck}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deckCreate>>, {data: Deck}> = (props) => {
          const {data} = props ?? {};

          return  deckCreate(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeckCreateMutationResult = NonNullable<Awaited<ReturnType<typeof deckCreate>>>
    export type DeckCreateMutationBody = Deck
    export type DeckCreateMutationError = unknown

    /**
 * @summary Create deck
 */
export const useDeckCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deckCreate>>, TError,{data: Deck}, TContext>, }
) => {

      const mutationOptions = getDeckCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * deck update
 * @summary Edit deck
 */
export const deckUpdate = (
    deckId: string,
    deck: Deck,
 ) => {
      
      
      return instance<Deck>(
      {url: `/flashcards/decks/${deckId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: deck
    },
      );
    }
  


export const getDeckUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deckUpdate>>, TError,{deckId: string;data: Deck}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deckUpdate>>, TError,{deckId: string;data: Deck}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deckUpdate>>, {deckId: string;data: Deck}> = (props) => {
          const {deckId,data} = props ?? {};

          return  deckUpdate(deckId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeckUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof deckUpdate>>>
    export type DeckUpdateMutationBody = Deck
    export type DeckUpdateMutationError = unknown

    /**
 * @summary Edit deck
 */
export const useDeckUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deckUpdate>>, TError,{deckId: string;data: Deck}, TContext>, }
) => {

      const mutationOptions = getDeckUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete deck
 */
export const deleteDeck = (
    deckId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/flashcards/decks/${deckId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteDeckMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDeck>>, TError,{deckId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteDeck>>, TError,{deckId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteDeck>>, {deckId: string}> = (props) => {
          const {deckId} = props ?? {};

          return  deleteDeck(deckId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteDeckMutationResult = NonNullable<Awaited<ReturnType<typeof deleteDeck>>>
    
    export type DeleteDeckMutationError = unknown

    export const useDeleteDeck = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteDeck>>, TError,{deckId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteDeckMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Update deck last studied
 */
export const updateDeckLastStudied = (
    deckId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/flashcards/decks/${deckId}/last-studied`, method: 'PUT'
    },
      );
    }
  


export const getUpdateDeckLastStudiedMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDeckLastStudied>>, TError,{deckId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateDeckLastStudied>>, TError,{deckId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateDeckLastStudied>>, {deckId: string}> = (props) => {
          const {deckId} = props ?? {};

          return  updateDeckLastStudied(deckId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateDeckLastStudiedMutationResult = NonNullable<Awaited<ReturnType<typeof updateDeckLastStudied>>>
    
    export type UpdateDeckLastStudiedMutationError = unknown

    export const useUpdateDeckLastStudied = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateDeckLastStudied>>, TError,{deckId: string}, TContext>, }
) => {

      const mutationOptions = getUpdateDeckLastStudiedMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * tag create
 * @summary Create tag
 */
export const addFlashcardTag = (
    flashcardTag: FlashcardTag,
 ) => {
      
      
      return instance<FlashcardTag>(
      {url: `/flashcards/tags`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: flashcardTag
    },
      );
    }
  


export const getAddFlashcardTagMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFlashcardTag>>, TError,{data: FlashcardTag}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addFlashcardTag>>, TError,{data: FlashcardTag}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addFlashcardTag>>, {data: FlashcardTag}> = (props) => {
          const {data} = props ?? {};

          return  addFlashcardTag(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type AddFlashcardTagMutationResult = NonNullable<Awaited<ReturnType<typeof addFlashcardTag>>>
    export type AddFlashcardTagMutationBody = FlashcardTag
    export type AddFlashcardTagMutationError = unknown

    /**
 * @summary Create tag
 */
export const useAddFlashcardTag = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addFlashcardTag>>, TError,{data: FlashcardTag}, TContext>, }
) => {

      const mutationOptions = getAddFlashcardTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete tag
 */
export const deleteFlashcardTag = (
    tagId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/flashcards/tags/${tagId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteFlashcardTagMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardTag>>, TError,{tagId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardTag>>, TError,{tagId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteFlashcardTag>>, {tagId: string}> = (props) => {
          const {tagId} = props ?? {};

          return  deleteFlashcardTag(tagId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteFlashcardTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteFlashcardTag>>>
    
    export type DeleteFlashcardTagMutationError = unknown

    export const useDeleteFlashcardTag = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteFlashcardTag>>, TError,{tagId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteFlashcardTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * tags
 * @summary Retrieve all tags from the user
 */
export const tagsList = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<TagsList200>(
      {url: `/flashcards/tags/list`, method: 'GET', signal
    },
      );
    }
  

export const getTagsListQueryKey = () => {
    return [`/flashcards/tags/list`] as const;
    }

    
export const getTagsListQueryOptions = <TData = Awaited<ReturnType<typeof tagsList>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof tagsList>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTagsListQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof tagsList>>> = ({ signal }) => tagsList(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof tagsList>>, TError, TData> & { queryKey: QueryKey }
}

export type TagsListQueryResult = NonNullable<Awaited<ReturnType<typeof tagsList>>>
export type TagsListQueryError = unknown

/**
 * @summary Retrieve all tags from the user
 */
export const useTagsList = <TData = Awaited<ReturnType<typeof tagsList>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof tagsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getTagsListQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



