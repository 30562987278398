import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'pt',
    supportedLngs: ['pt'],
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,
    },
    defaultNS: 'translation',
    ns: ['translation'],
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`,
      crossDomain: true,
    },
  })

if (import.meta.hot) {
  import.meta.hot.on('locales-update', () => {
    i18n.reloadResources().then(() => {
      i18n.changeLanguage(i18n.language)
    })
  })
}

export default i18n
