/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  CategoriesParams,
  Category,
  CategoryImage,
  CreateExamBody,
  Difficulty,
  Exam,
  ExamCatalog200,
  ExamCatalogFree200,
  ExamCatalogFreeParams,
  ExamCatalogParams,
  ExamSummary,
  Exams200,
  ExamsParams,
  ExamsStatistics200Item,
  ExamsStatisticsParams,
  Relevance,
  UpdateExam200,
  UpdateExamBody
} from '.././model'
import { instance } from '.././instance';



/**
 * Get categories
 * @summary Your GET endpoint
 */
export const categories = (
    params?: CategoriesParams,
 signal?: AbortSignal
) => {
      
      
      return instance<Category[]>(
      {url: `/categories`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getCategoriesQueryKey = (params?: CategoriesParams,) => {
    return [`/categories`, ...(params ? [params]: [])] as const;
    }

    
export const getCategoriesQueryOptions = <TData = Awaited<ReturnType<typeof categories>>, TError = unknown>(params?: CategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categories>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCategoriesQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof categories>>> = ({ signal }) => categories(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof categories>>, TError, TData> & { queryKey: QueryKey }
}

export type CategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof categories>>>
export type CategoriesQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useCategories = <TData = Awaited<ReturnType<typeof categories>>, TError = unknown>(
 params?: CategoriesParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categories>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCategoriesQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get categories images
 * @summary Your GET endpoint
 */
export const categoriesImages = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<CategoryImage[]>(
      {url: `/categories/images`, method: 'GET', signal
    },
      );
    }
  

export const getCategoriesImagesQueryKey = () => {
    return [`/categories/images`] as const;
    }

    
export const getCategoriesImagesQueryOptions = <TData = Awaited<ReturnType<typeof categoriesImages>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesImages>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCategoriesImagesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof categoriesImages>>> = ({ signal }) => categoriesImages(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof categoriesImages>>, TError, TData> & { queryKey: QueryKey }
}

export type CategoriesImagesQueryResult = NonNullable<Awaited<ReturnType<typeof categoriesImages>>>
export type CategoriesImagesQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useCategoriesImages = <TData = Awaited<ReturnType<typeof categoriesImages>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof categoriesImages>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getCategoriesImagesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get Difficulties
 * @summary Your GET endpoint
 */
export const difficulties = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<Difficulty[]>(
      {url: `/difficulties`, method: 'GET', signal
    },
      );
    }
  

export const getDifficultiesQueryKey = () => {
    return [`/difficulties`] as const;
    }

    
export const getDifficultiesQueryOptions = <TData = Awaited<ReturnType<typeof difficulties>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof difficulties>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getDifficultiesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof difficulties>>> = ({ signal }) => difficulties(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof difficulties>>, TError, TData> & { queryKey: QueryKey }
}

export type DifficultiesQueryResult = NonNullable<Awaited<ReturnType<typeof difficulties>>>
export type DifficultiesQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useDifficulties = <TData = Awaited<ReturnType<typeof difficulties>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof difficulties>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getDifficultiesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get Difficulties
 * @summary Your GET endpoint
 */
export const relevance = (
    
 signal?: AbortSignal
) => {
      
      
      return instance<Relevance[]>(
      {url: `/relevance`, method: 'GET', signal
    },
      );
    }
  

export const getRelevanceQueryKey = () => {
    return [`/relevance`] as const;
    }

    
export const getRelevanceQueryOptions = <TData = Awaited<ReturnType<typeof relevance>>, TError = unknown>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof relevance>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getRelevanceQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof relevance>>> = ({ signal }) => relevance(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof relevance>>, TError, TData> & { queryKey: QueryKey }
}

export type RelevanceQueryResult = NonNullable<Awaited<ReturnType<typeof relevance>>>
export type RelevanceQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useRelevance = <TData = Awaited<ReturnType<typeof relevance>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof relevance>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getRelevanceQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get exam catalog
 * @summary Your GET endpoint
 */
export const examCatalogFree = (
    params?: ExamCatalogFreeParams,
 signal?: AbortSignal
) => {
      
      
      return instance<ExamCatalogFree200>(
      {url: `/catalog/exams/free`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExamCatalogFreeQueryKey = (params?: ExamCatalogFreeParams,) => {
    return [`/catalog/exams/free`, ...(params ? [params]: [])] as const;
    }

    
export const getExamCatalogFreeQueryOptions = <TData = Awaited<ReturnType<typeof examCatalogFree>>, TError = unknown>(params?: ExamCatalogFreeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examCatalogFree>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamCatalogFreeQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof examCatalogFree>>> = ({ signal }) => examCatalogFree(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof examCatalogFree>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamCatalogFreeQueryResult = NonNullable<Awaited<ReturnType<typeof examCatalogFree>>>
export type ExamCatalogFreeQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExamCatalogFree = <TData = Awaited<ReturnType<typeof examCatalogFree>>, TError = unknown>(
 params?: ExamCatalogFreeParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examCatalogFree>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamCatalogFreeQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get Exams Statistics
 * @summary Your GET endpoint
 */
export const examsStatistics = (
    params: ExamsStatisticsParams,
 signal?: AbortSignal
) => {
      
      
      return instance<ExamsStatistics200Item[]>(
      {url: `/exams/statistics`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExamsStatisticsQueryKey = (params: ExamsStatisticsParams,) => {
    return [`/exams/statistics`, ...(params ? [params]: [])] as const;
    }

    
export const getExamsStatisticsQueryOptions = <TData = Awaited<ReturnType<typeof examsStatistics>>, TError = unknown>(params: ExamsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examsStatistics>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamsStatisticsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof examsStatistics>>> = ({ signal }) => examsStatistics(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof examsStatistics>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamsStatisticsQueryResult = NonNullable<Awaited<ReturnType<typeof examsStatistics>>>
export type ExamsStatisticsQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExamsStatistics = <TData = Awaited<ReturnType<typeof examsStatistics>>, TError = unknown>(
 params: ExamsStatisticsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examsStatistics>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamsStatisticsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Create exam
 */
export const createExam = (
    createExamBody: CreateExamBody,
 ) => {
      
      
      return instance<Exam>(
      {url: `/exams/order`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: createExamBody
    },
      );
    }
  


export const getCreateExamMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExam>>, TError,{data: CreateExamBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createExam>>, TError,{data: CreateExamBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createExam>>, {data: CreateExamBody}> = (props) => {
          const {data} = props ?? {};

          return  createExam(data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type CreateExamMutationResult = NonNullable<Awaited<ReturnType<typeof createExam>>>
    export type CreateExamMutationBody = CreateExamBody
    export type CreateExamMutationError = unknown

    export const useCreateExam = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createExam>>, TError,{data: CreateExamBody}, TContext>, }
) => {

      const mutationOptions = getCreateExamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get exam
 * @summary Your GET endpoint
 */
export const exam = (
    examId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<Exam>(
      {url: `/exams/${examId}`, method: 'GET', signal
    },
      );
    }
  

export const getExamQueryKey = (examId: string,) => {
    return [`/exams/${examId}`] as const;
    }

    
export const getExamQueryOptions = <TData = Awaited<ReturnType<typeof exam>>, TError = unknown>(examId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof exam>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exam>>> = ({ signal }) => exam(examId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exam>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamQueryResult = NonNullable<Awaited<ReturnType<typeof exam>>>
export type ExamQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExam = <TData = Awaited<ReturnType<typeof exam>>, TError = unknown>(
 examId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof exam>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamQueryOptions(examId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Update exam
 */
export const updateExam = (
    examId: string,
    updateExamBody: UpdateExamBody,
 ) => {
      
      
      return instance<UpdateExam200>(
      {url: `/exams/${examId}`, method: 'PUT',
      headers: {'Content-Type': 'application/json', },
      data: updateExamBody
    },
      );
    }
  


export const getUpdateExamMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExam>>, TError,{examId: string;data: UpdateExamBody}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof updateExam>>, TError,{examId: string;data: UpdateExamBody}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof updateExam>>, {examId: string;data: UpdateExamBody}> = (props) => {
          const {examId,data} = props ?? {};

          return  updateExam(examId,data,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type UpdateExamMutationResult = NonNullable<Awaited<ReturnType<typeof updateExam>>>
    export type UpdateExamMutationBody = UpdateExamBody
    export type UpdateExamMutationError = unknown

    export const useUpdateExam = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof updateExam>>, TError,{examId: string;data: UpdateExamBody}, TContext>, }
) => {

      const mutationOptions = getUpdateExamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete exam
 */
export const deleteExam = (
    examId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/exams/${examId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteExamMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExam>>, TError,{examId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteExam>>, TError,{examId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteExam>>, {examId: string}> = (props) => {
          const {examId} = props ?? {};

          return  deleteExam(examId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteExamMutationResult = NonNullable<Awaited<ReturnType<typeof deleteExam>>>
    
    export type DeleteExamMutationError = unknown

    export const useDeleteExam = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteExam>>, TError,{examId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteExamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Finish exam
 */
export const finishExam = (
    examId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/exams/${examId}/finish`, method: 'PUT'
    },
      );
    }
  


export const getFinishExamMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishExam>>, TError,{examId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof finishExam>>, TError,{examId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof finishExam>>, {examId: string}> = (props) => {
          const {examId} = props ?? {};

          return  finishExam(examId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type FinishExamMutationResult = NonNullable<Awaited<ReturnType<typeof finishExam>>>
    
    export type FinishExamMutationError = unknown

    export const useFinishExam = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof finishExam>>, TError,{examId: string}, TContext>, }
) => {

      const mutationOptions = getFinishExamMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Get exams
 * @summary Your GET endpoint
 */
export const examSummary = (
    examId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<ExamSummary>(
      {url: `/exams/${examId}/summary`, method: 'GET', signal
    },
      );
    }
  

export const getExamSummaryQueryKey = (examId: string,) => {
    return [`/exams/${examId}/summary`] as const;
    }

    
export const getExamSummaryQueryOptions = <TData = Awaited<ReturnType<typeof examSummary>>, TError = unknown>(examId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examSummary>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamSummaryQueryKey(examId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof examSummary>>> = ({ signal }) => examSummary(examId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(examId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof examSummary>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamSummaryQueryResult = NonNullable<Awaited<ReturnType<typeof examSummary>>>
export type ExamSummaryQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExamSummary = <TData = Awaited<ReturnType<typeof examSummary>>, TError = unknown>(
 examId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examSummary>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamSummaryQueryOptions(examId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * exams
 * @summary Your GET endpoint
 */
export const exams = (
    params?: ExamsParams,
 signal?: AbortSignal
) => {
      
      
      return instance<Exams200>(
      {url: `/exams`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExamsQueryKey = (params?: ExamsParams,) => {
    return [`/exams`, ...(params ? [params]: [])] as const;
    }

    
export const getExamsQueryOptions = <TData = Awaited<ReturnType<typeof exams>>, TError = unknown>(params?: ExamsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof exams>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamsQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof exams>>> = ({ signal }) => exams(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof exams>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamsQueryResult = NonNullable<Awaited<ReturnType<typeof exams>>>
export type ExamsQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExams = <TData = Awaited<ReturnType<typeof exams>>, TError = unknown>(
 params?: ExamsParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof exams>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamsQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Get exam catalog
 * @summary Your GET endpoint
 */
export const examCatalog = (
    params?: ExamCatalogParams,
 signal?: AbortSignal
) => {
      
      
      return instance<ExamCatalog200>(
      {url: `/catalog/exams`, method: 'GET',
        params, signal
    },
      );
    }
  

export const getExamCatalogQueryKey = (params?: ExamCatalogParams,) => {
    return [`/catalog/exams`, ...(params ? [params]: [])] as const;
    }

    
export const getExamCatalogQueryOptions = <TData = Awaited<ReturnType<typeof examCatalog>>, TError = unknown>(params?: ExamCatalogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examCatalog>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExamCatalogQueryKey(params);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof examCatalog>>> = ({ signal }) => examCatalog(params, signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof examCatalog>>, TError, TData> & { queryKey: QueryKey }
}

export type ExamCatalogQueryResult = NonNullable<Awaited<ReturnType<typeof examCatalog>>>
export type ExamCatalogQueryError = unknown

/**
 * @summary Your GET endpoint
 */
export const useExamCatalog = <TData = Awaited<ReturnType<typeof examCatalog>>, TError = unknown>(
 params?: ExamCatalogParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof examCatalog>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getExamCatalogQueryOptions(params,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Use exam from catalog
 */
export const examFromCatalog = (
    originalExamId: string,
 ) => {
      
      
      return instance<Exam>(
      {url: `/exams/${originalExamId}/order`, method: 'POST'
    },
      );
    }
  


export const getExamFromCatalogMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof examFromCatalog>>, TError,{originalExamId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof examFromCatalog>>, TError,{originalExamId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof examFromCatalog>>, {originalExamId: string}> = (props) => {
          const {originalExamId} = props ?? {};

          return  examFromCatalog(originalExamId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type ExamFromCatalogMutationResult = NonNullable<Awaited<ReturnType<typeof examFromCatalog>>>
    
    export type ExamFromCatalogMutationError = unknown

    export const useExamFromCatalog = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof examFromCatalog>>, TError,{originalExamId: string}, TContext>, }
) => {

      const mutationOptions = getExamFromCatalogMutationOptions(options);

      return useMutation(mutationOptions);
    }
    