const extractContentFromHTML = (s?: string) => {
  if (!s) {
    return ''
  }

  const span = document.createElement('span')
  span.innerHTML = s
  return span.textContent || span.innerText
}

export default extractContentFromHTML
