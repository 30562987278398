import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const BulletPoint = forwardRef<any, IconProps>(
  ({ size, color, ...rest }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      version="1.1"
      width={size}
      height={size}
      ref={ref}
      fill={color}
      viewBox="0 0 100 125"
    >
      <circle cx="50" cy="50" r="40" />
    </svg>
  ),
)

export default BulletPoint
