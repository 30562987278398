const replaceImageUrlFromHTMLString = (str: string, strToReplace: string) => {
  const rex = /src=["'](data:[^"']+)["']/

  const newUrl = `src="${strToReplace}"`

  const updatedHtmlString = str.replace(rex, newUrl)
  return updatedHtmlString
}

export default replaceImageUrlFromHTMLString
