/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * qb-api
 * QB API
 * OpenAPI spec version: 1.0
 */
import {
  useMutation,
  useQuery
} from 'react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  UseQueryResult
} from 'react-query'
import type {
  Topic
} from '.././model'
import { instance } from '.././instance';



/**
 * Get topic
 */
export const getTopic = (
    topicId: string,
 signal?: AbortSignal
) => {
      
      
      return instance<Topic>(
      {url: `/notepad/topics/${topicId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetTopicQueryKey = (topicId: string,) => {
    return [`/notepad/topics/${topicId}`] as const;
    }

    
export const getGetTopicQueryOptions = <TData = Awaited<ReturnType<typeof getTopic>>, TError = unknown>(topicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTopic>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetTopicQueryKey(topicId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getTopic>>> = ({ signal }) => getTopic(topicId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(topicId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getTopic>>, TError, TData> & { queryKey: QueryKey }
}

export type GetTopicQueryResult = NonNullable<Awaited<ReturnType<typeof getTopic>>>
export type GetTopicQueryError = unknown

export const useGetTopic = <TData = Awaited<ReturnType<typeof getTopic>>, TError = unknown>(
 topicId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getTopic>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getGetTopicQueryOptions(topicId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * Delete topic
 */
export const deleteTopic = (
    topicId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/notepad/topics/${topicId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTopicMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTopic>>, TError,{topicId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTopic>>, TError,{topicId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTopic>>, {topicId: string}> = (props) => {
          const {topicId} = props ?? {};

          return  deleteTopic(topicId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTopicMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTopic>>>
    
    export type DeleteTopicMutationError = unknown

    export const useDeleteTopic = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTopic>>, TError,{topicId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteTopicMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * Delete tag
 */
export const deleteTag = (
    tagId: string,
 ) => {
      
      
      return instance<void>(
      {url: `/notepad/tags/${tagId}`, method: 'DELETE'
    },
      );
    }
  


export const getDeleteTagMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTag>>, TError,{tagId: string}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof deleteTag>>, TError,{tagId: string}, TContext> => {
 const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteTag>>, {tagId: string}> = (props) => {
          const {tagId} = props ?? {};

          return  deleteTag(tagId,)
        }

        


   return  { mutationFn, ...mutationOptions }}

    export type DeleteTagMutationResult = NonNullable<Awaited<ReturnType<typeof deleteTag>>>
    
    export type DeleteTagMutationError = unknown

    export const useDeleteTag = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof deleteTag>>, TError,{tagId: string}, TContext>, }
) => {

      const mutationOptions = getDeleteTagMutationOptions(options);

      return useMutation(mutationOptions);
    }
    