import { Modal } from '../../molecules';
import { useTranslation } from 'react-i18next';

function PaymentFinished() {
  const { t } = useTranslation();
  return (
    <div style={{ width: "100vw", height: "100vh", backgroundColor: "#212529"}}>
      <Modal
        show
        title={t("account.billing.purchasePlan.payment.finishedTitle")}
        body={t("account.billing.purchasePlan.payment.finishedBody")}
      />
    </div>
  );
}

export default PaymentFinished;
