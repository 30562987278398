import clsx from 'clsx'
import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Download, Pencil, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Collection } from '../../api/model'
import { Typography } from '../../atoms'
import classes from './NotepadCollectionTitleDisplay.module.css'

interface NotepadCollectionTitleDisplayProps {
  notepadCollectionIdx: number
  notepadCollection: Collection
  editMode?: boolean
  onEditNotepadCollectionClicked: (notepadCollectionIdx: number) => void
  onExportNotepadCollectionClicked: (notepadCollection: Collection) => void
  onDeleteNotepadCollectionClicked: (notepadCollectionIdx: number) => void
}

const NotepadCollectionTitleDisplay: FC<NotepadCollectionTitleDisplayProps> = ({
  notepadCollectionIdx,
  notepadCollection,
  editMode = true,
  onEditNotepadCollectionClicked,
  onExportNotepadCollectionClicked,
  onDeleteNotepadCollectionClicked,
}) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center">
      <div
        className={clsx(classes.circle, 'mr-1')}
        style={{
          backgroundColor: notepadCollection.color,
          width: 20,
          height: 20,
          marginRight: 10,
          padding: 5,
          border: '0.5px solid rgb(33, 37, 41)',
        }}
      />
      <Typography className={classes.notepadCollectionName} variant="h5">
        {notepadCollection.title}
      </Typography>
      {editMode && (
        <Dropdown>
          <Dropdown.Toggle
            variant="default"
            className={classes.notepadCollectionMenuDropdown}
          />
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={() => onEditNotepadCollectionClicked(notepadCollectionIdx)}
            >
              <div className="d-flex align-items-center">
                <Pencil className={classes.icon} />
                {t('common.edit')}
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => onExportNotepadCollectionClicked(notepadCollection)}
            >
              <div className="d-flex align-items-center">
                <Download className={classes.icon} />
                {t('common.export')}
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="3"
              onClick={() => onDeleteNotepadCollectionClicked(notepadCollectionIdx)}
            >
              <div className="d-flex align-items-center">
                <Trash className={classes.icon} />
                {t('common.delete')}
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}

export default NotepadCollectionTitleDisplay
