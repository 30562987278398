import clsx from 'clsx'
import { FC } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import ReactCardFlip from 'react-card-flip'
import { useTranslation } from 'react-i18next'
import { Loading, Typography } from '../../atoms'
import RichTextEditor from '../../atoms/RichTextEditor/RichTextEditor'
import classes from './Flashcard.module.css'

export enum Levels {
  EASY,
  MODERATE,
  HARD,
}
export interface flashcardProperties {
  frontSideText: string
  backSideText: string
}

export interface FlashcardProps {
  frontSideText: string
  backSideText: string
  frontSideSelected: boolean
  showAnswer: boolean
  editMode?: boolean
  isLoading?: boolean
  showMissingFrontSideText?: boolean
  showMissingBackSideText?: boolean
  setFrontSideSelected: (value: boolean) => void
  setShowAnswer: (value: boolean) => void
  setUserAlreadyAnswered?: (value: boolean) => void
  setFrontSideText?: (text?: string) => void
  setBackSideText?: (text?: string) => void
}

const Flashcard: FC<FlashcardProps> = ({
  frontSideText,
  backSideText,
  frontSideSelected,
  showAnswer,
  editMode = false,
  isLoading = false,
  showMissingFrontSideText = false,
  showMissingBackSideText = false,
  setFrontSideSelected,
  setShowAnswer,
  setUserAlreadyAnswered = () => { },
  setFrontSideText = () => { },
  setBackSideText = () => { },
}) => {
  const { t } = useTranslation()

  const onSwitchFlashcardSide = () => {
    setShowAnswer(!showAnswer)
    setFrontSideSelected(!frontSideSelected)
    setUserAlreadyAnswered(true)
  }

  return (
    <Card className={classes.flashcardContainer}>
      <Card.Body style={{ padding: 0 }}>
        <Row>
          <Col
            id="editor-mode-frontside"
            className={classes.flashcardSideContainer}
            lg={editMode ? 6 : 12}
          >
            <p className={classes.errorMessage}>
              {showMissingFrontSideText
                ? t('flashcards.errors.frontsideEmpty')
                : null}
            </p>
            {!editMode ? (
              <div
                className={clsx(
                  classes.flashcardSideWrapper,
                  classes.cursorPointer,
                )}
              >
                {!isLoading ? (
                  <ReactCardFlip
                    isFlipped={showAnswer}
                    flipDirection="horizontal"
                    infinite={true}
                  >
                    <div id="frontside" onClick={() => onSwitchFlashcardSide()}>
                      <div className={clsx(classes.flashcardSide)}>
                        <Typography variant="h5">
                          <div
                            className={classes.flashcardText}
                            dangerouslySetInnerHTML={{ __html: frontSideText }}
                          />
                        </Typography>
                      </div>
                    </div>
                    <div id="backside" onClick={() => onSwitchFlashcardSide()}>
                      <div className={clsx(classes.flashcardSide)}>
                        <Typography variant="h5">
                          <div
                            className={classes.flashcardText}
                            dangerouslySetInnerHTML={{ __html: backSideText }}
                          />
                        </Typography>
                      </div>
                    </div>
                  </ReactCardFlip>
                ) : (
                  <div className={clsx(classes.flashcardSide)}>
                    <Loading />
                  </div>
                )}
                <div className="pb-3" style={{ height: 30 }}>
                  {showAnswer
                    ? t('flashcards.clickToShowQuestion')
                    : t('flashcards.clickToShowAnswer')}
                </div>
              </div>
            ) : (
              <RichTextEditor
                initialContent={frontSideText}
                showSaveButton={false}
                onContentChange={setFrontSideText}
                placeholderText={t('flashcards.frontSidePlaceholder')}
              />
            )}
          </Col>
          {editMode && (
            <Col
              lg={6}
              id="editor-mode-backside"
              className={classes.flashcardSideContainer}
            >
              <p className={classes.errorMessage}>
                {showMissingBackSideText
                  ? t('flashcards.errors.backsideEmpty')
                  : null}
              </p>
              <RichTextEditor
                initialContent={backSideText}
                showSaveButton={false}
                onContentChange={setBackSideText}
                placeholderText={t('flashcards.backSidePlaceholder')}
              />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Flashcard
