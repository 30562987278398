import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const User = forwardRef<any, IconProps>(({ size, color, ...rest }, ref) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    ref={ref}
    fill={color}
    viewBox="0 0 34 34"
  >
    <path
      id="Icon_ionic-md-person"
      data-name="Icon ionic-md-person"
      d="M21.5,21.5A8.5,8.5,0,1,0,13,13,8.525,8.525,0,0,0,21.5,21.5Zm0,4.25c-5.631,0-17,2.869-17,8.5V38.5h34V34.25C38.5,28.619,27.131,25.75,21.5,25.75Z"
      transform="translate(-4.5 -4.5)"
      fill={color}
    />
  </svg>
))

export default User
