//ref https://github.com/Treora/dom-highlight-range/blob/master/highlight-range.js

import { v4 as uuid } from 'uuid'

export type HighlightRangeI = ReturnType<typeof HighlightRange>

function getNextNode(node: Node) {
  if (node.firstChild) return node.firstChild
  while (node) {
    if (node.nextSibling) return node.nextSibling
    node = node.parentNode!
  }
}

function getNodesInRange(range: Range) {
  const start = range.startContainer
  const end = range.endContainer
  const nodes = []

  // walk children and siblings from start until end is found
  for (let node = start; node; node = getNextNode(node)) {
    if (node.parentNode?.nodeName === 'STRONG') {
      nodes.push(node.parentNode)
    }
    nodes.push(node)
    if (node === end) break
  }

  return nodes
}

const getSafeRanges = (dangerous: Range): Range[] => {
  const allNodes = getNodesInRange(dangerous)

  // Starts -- Work inward from the start, selecting the largest safe range
  const s: Node[] = [],
    rs: Range[] = []
  for (let i = 0; i < allNodes.length; i++) {
    s.push(allNodes[i])
  }

  let newRange = document.createRange()
  for (let i = 0; i < s.length; i++) {
    // If we've hit the starting node, set it as the start of the range
    if (s[i] === dangerous.startContainer) {
      newRange.setStart(s[i], dangerous.startOffset)

      // If we've hit the ending node, complete the range
      if (s[i] === dangerous.endContainer) {
        newRange.setEnd(s[i], dangerous.endOffset)
      } else {
        newRange.setEndAfter(
          s[i].nodeType == Node.TEXT_NODE ? s[i] : s[i].lastChild!,
        )
      }
    } else if (s[i] === dangerous.endContainer) {
      // We've hit the ending node, set it as the endpoint
      newRange.setStartBefore(
        s[i].nodeType == Node.TEXT_NODE ? s[i] : s[i].firstChild!,
      )

      newRange.setEnd(s[i], dangerous.endOffset)
    } else if (s[i].nodeType === Node.TEXT_NODE) {
      newRange = document.createRange()
      newRange.setStartBefore(s[i])
      newRange.setEndAfter(s[i])
    } else if (s[i].firstChild?.nodeType === Node.TEXT_NODE) {
      newRange = document.createRange()
      newRange.setStartBefore(s[i].firstChild!)
      newRange.setEndAfter(s[i].firstChild!)
    }

    rs.push(newRange)
  }

  // Send to Console
  return rs
}

const highlightRange = (range: Range, tagName: string, attributes: any) => {
  const newNode = document.createElement(tagName)
  if (attributes) {
    for (const key in attributes) {
      newNode.setAttribute(key, attributes[key])
    }
  }
  const ranges = range.toString().split('\n')

  if (ranges.length > 1) {
    getSafeRanges(range).forEach((r) => {
      range.surroundContents(newNode)
    })
  } else {
    range.surroundContents(newNode)
  }
}

export const HighlightRange = () => {
  const makeHighlight = (
    range: Range,
    tagName?: string,
    attributes?: { [key: string]: string },
    canHighlight?: boolean,
  ): string | null => {
    if (range.collapsed) return null

    // id for removing highlight
    const id = uuid()

    if (canHighlight) {
      const newAttributes = { ...attributes, highlightid: id, highlighted: '' }

      const safeRanges = getSafeRanges(range)
      for (let i = 0; i < safeRanges.length; i++) {
        highlightRange(safeRanges[i], tagName, newAttributes)
      }
    }

    return id
  }

  const removeNodesHighlight = (highlightedNodes: NodeListOf<Element>) => {
    let parentNode = undefined
    for (let i = 0; i < highlightedNodes.length; i++) {
      const node = highlightedNodes[i]
      parentNode = node.parentNode
      if (node.childNodes.length === 1)
        parentNode?.replaceChild(node.firstChild as Text, node)
      else {
        while (node.firstChild)
          parentNode?.insertBefore(node.firstChild as Text, node)
        parentNode?.removeChild(node)
      }
      if (i === 0) parentNode?.normalize()
      if (i === highlightedNodes.length - 1) parentNode?.normalize()
    }

    return parentNode
  }

  const removeHighlight = (
    highlightid: string,
    rootElement: Document | HTMLElement = document,
  ) => {
    const highlightedNodes = rootElement.querySelectorAll(
      `[highlightid="${highlightid}"]`,
    )

    // Necessary to detect if its the question description or explanation
    const parentNode = removeNodesHighlight(highlightedNodes)

    return parentNode
  }

  const removeAllHighlights = (
    rootElement: Document | HTMLElement = document,
  ) => {
    const highlightedNodes = rootElement.querySelectorAll('[highlightid]')

    removeNodesHighlight(highlightedNodes)
  }

  return { makeHighlight, removeHighlight, removeAllHighlights }
}
