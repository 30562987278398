import { FC, useEffect, useMemo, useState } from 'react'
import classes from './TagsInput.module.css'
import { Check, Tag, X } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'

interface TagsInputProps {
  parent: any
  tags: string[]
  placeholder?: string
  readOnly?: boolean
  onAddTag: (tag: string) => void
  onDeleteTag: (index: number) => void
}

const TagsInput: FC<TagsInputProps> = ({
  parent,
  tags,
  readOnly = false,
  placeholder,
  onAddTag,
  onDeleteTag,
}) => {
  const maxInputWidth = 200; // Maximum width for the input field in pixels

  const [myTags, setMyTags] = useState<string[]>(tags)
  const [newTag, setNewTag] = useState<string>('')

  const isTyping = useMemo(() => newTag.trim().length > 0, [newTag])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewTag(e.target.value);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return

    handleSubmitNewTag()
  }

  const handleSubmitNewTag = () => {
    const value = newTag.trim()

    if (!value.trim()) return

    onAddTag(value)

    setNewTag('')
  }

  const removeTag = (index: number) => {
    onDeleteTag(index)
  }

  useEffect(() => {
    if (tags) {
      setMyTags(tags)
    }
  }, [tags])

  useEffect(() => {
    setNewTag('')
  }, [parent])

  return (
    <div className={classes.tagsInputContainer}>
      <Tag size={18} className="me-2" />
      {myTags.map((tag, index) => (
        <div className={classes.tagItem} key={index}>
          <span className={classes.text}>{tag}</span>
          {!readOnly && (
            <span className={classes.icon} onClick={() => removeTag(index)}>
              <X size={18} />
            </span>
          )}
        </div>
      ))}
      {
        !readOnly && (
          <div className='d-flex'>
            <div>
              <input
                type="text"
                className={classes.tagsInput}
                value={newTag}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder={placeholder}
              />
            </div>
            {
              isTyping && (
                <div className='d-flex align-items-center'>
                  <span className={classes.icon} onClick={handleSubmitNewTag}>
                    <Check size={18} color='green' />
                  </span>
                  <span className={classes.icon} onClick={() => setNewTag('')}>
                    <X size={18} color='black' />
                  </span>
                </div>
              )}
          </div>
        )
      }
    </div>
  )
}

export default TagsInput
