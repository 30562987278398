const calculateMD5Hash = (data?: string): number => {
  if (!data) {
    return 0
  }

  let hash = 5381 // Initial hash value

  for (let i = 0; i < data.length; i++) {
    const char = data.charCodeAt(i)
    hash = (hash * 33) ^ char // The '33' is a prime number often used in DJB2
  }
  return hash >>> 0 // Ensure it's a positive integer
}

export default calculateMD5Hash
