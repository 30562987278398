import { CourseBook, Forum, TipsAndTricks } from '../assets/icons'

const externalLinkItems = [
  {
    slug: 'nav-forum',
    description: 'navigation.forum',
    to: 'https://discourse.perguntasqb.pt/',
    icon: Forum,
    className: 'forum',
  },
  {
    slug: 'nav-course-book',
    description: 'navigation.courseBook',
    to: 'https://drive.google.com/file/d/1lwm9PCPKQuTkrc6K6kjBq5i3SCjpoGgZ/view',
    icon: CourseBook,
    className: 'courseBook',
  },
  {
    slug: 'nav-tips-and-tricks',
    description: 'navigation.tipsAndTricks',
    to: 'https://drive.google.com/file/d/1zaG1n_vWFhr-MtlRepl6Gs0hWZAeZa9H/view',
    icon: TipsAndTricks,
    className: 'tipsAndTricks',
  },
]

export default externalLinkItems
