const tutorialOptions = {
  doneLabel: 'Próxima página', // Don't delete, not able to use translation here
  nextLabel: 'Próximo',
  prevLabel: 'Anterior',
  disableInteraction: true,
  exitOnOverlayClick: false,
  showProgress: true,
  showButtons: true,
  showStepNumbers: true,
  showBullets: false,
  exitOnEsc: false,
  hidePrev: true,
  keyboardNavigation: true,
  scrollToElement: false,
}

export default tutorialOptions
