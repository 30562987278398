import { FC, useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import classes from './RichTextEditor.module.css'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.core.css'
import 'react-quill/dist/quill.snow.css'

import { Button, Col, Form, Modal, Row } from 'react-bootstrap'

import { useUploadImage } from '../../api/default/default'
import calculateMD5Hash from '../../utils/calculateHash'
import getImagesUrlFromHTMLString from '../../utils/getImagesUrlFromHTMLString'
import replaceImageUrlFromHTMLString from '../../utils/replaceImageUrlFromHTMLString'
import Loading from '../Loading/Loading'

interface RichTextEditorProps {
  contentId?: string
  initialContent: string
  showSaveButton?: boolean
  placeholderText?: string
  withImages?: boolean
  onContentChange: (content: string | undefined) => void
  onSubmit?: () => void
  onDiscardChanges?: () => void
}

const RichTextEditor: FC<RichTextEditorProps> = ({
  contentId,
  initialContent,
  showSaveButton = true,
  placeholderText = '',
  withImages = false,
  onContentChange,
  onSubmit = () => {},
  onDiscardChanges = () => {},
}) => {
  const { t } = useTranslation()
  const editorRef = useRef(null)

  const { mutateAsync: uploadImage, isLoading } = useUploadImage()

  const toolbarOptions = {
    container: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent

      withImages ? ['image'] : [],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'],
    ],
  }

  const module = {
    toolbar: toolbarOptions,
  }

  const [editedContent, setEditedContent] = useState<string | undefined>(
    undefined,
  )

  const [unsavedChangesOnContent, setUnsavedChangesOnContent] =
    useState<boolean>(false)
  const [showDiscardChangesModal, setShowDiscardChangesModal] =
    useState<boolean>(false)

  const resetEditTopic = () => {
    setEditedContent('')
    onContentChange(undefined)
  }

  const discardChanges = () => {
    setShowDiscardChangesModal(false)
    resetEditTopic()
    onDiscardChanges()
    setEditedContent(initialContent)
    sessionStorage.removeItem('highlightedText')
    setHighlightedText(undefined)
  }

  const onHideDiscardChangesModal = () => {
    setShowDiscardChangesModal(false)
  }

  const getText = (htmlString: string) => {
    var divContainer = document.createElement('div')
    divContainer.innerHTML = htmlString
    return divContainer.textContent || divContainer.innerText || ''
  }

  const submitChanges = async (e: any) => {
    e.preventDefault()

    const imagesUrls = getImagesUrlFromHTMLString(editedContent!)

    for (const imageUrl of imagesUrls) {
      const rawImage = await fetch(imageUrl)

      const imageBlob = await rawImage.blob()

      const uploadImageRequestBody = {
        image: imageBlob,
      }

      const response = await uploadImage({ data: uploadImageRequestBody })

      const newImageUrl = response.link

      const newContent = replaceImageUrlFromHTMLString(
        editedContent!,
        newImageUrl,
      )

      onContentChange(newContent)
    }
    onSubmit()
    setUnsavedChangesOnContent(false)
  }

  useEffect(() => {
    const highlightedText = sessionStorage.getItem('highlightedText')

    if (highlightedText) {
      setUnsavedChangesOnContent(true)
    } else {
      setUnsavedChangesOnContent(false)
    }
    if (!initialContent) {
      setEditedContent(undefined)
    }
  }, [contentId])

  useEffect(() => {
    if (initialContent && initialContent !== 'undefined') {
      setEditedContent(initialContent)
    } else {
      setEditedContent(undefined)
    }
  }, [initialContent])

  useEffect(() => {
    if (
      !getText(initialContent) &&
      !getText(editedContent ?? '') &&
      !editedContent?.includes('<img')
    ) {
      setUnsavedChangesOnContent(false)
      return
    }

    const sanitizedContentHash = calculateMD5Hash(editedContent ?? '')
    const sanitizedInitialContentHash = calculateMD5Hash(initialContent ?? '')

    if (sanitizedContentHash === sanitizedInitialContentHash) {
      setUnsavedChangesOnContent(false)
    } else {
      setUnsavedChangesOnContent(true)
    }
  }, [editedContent])

  return (
    <>
      <div className={classes.textEditorContainer}>
        <Form onSubmit={submitChanges}>
          {isLoading ? ( //|| isLoadingInitialContent ? (
            <Loading />
          ) : (
            <>
              <ReactQuill
                ref={editorRef}
                className={classes.textEditor}
                theme="snow"
                modules={module}
                value={editedContent}
                placeholder={placeholderText}
                onChange={(value) => {
                  setEditedContent(value)
                  onContentChange(value)
                }}
              />
              <Row className="mt-2 align-items-center" style={{ height: 40 }}>
                <Col className="d-flex justify-content-end">
                  {showSaveButton && unsavedChangesOnContent ? (
                    <>
                      <Button
                        variant="dark"
                        onClick={() => setShowDiscardChangesModal(true)}
                        className="me-2"
                      >
                        {t('common.cancel')}
                      </Button>
                      <Button variant="success" type="submit" className="mb-0">
                        {t('common.saveChanges')}
                      </Button>
                    </>
                  ) : null}
                </Col>
              </Row>
            </>
          )}
        </Form>
      </div>
      <Modal
        show={showDiscardChangesModal}
        onHide={onHideDiscardChangesModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('common.unsavedChangesWarningTitle')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t('notepad.unsavedChangesWarningText')}</Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={onHideDiscardChangesModal}>
            {t('common.continue')}
          </Button>
          <Button variant="danger" onClick={discardChanges}>
            {t('common.exit')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RichTextEditor
