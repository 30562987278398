import clsx from 'clsx'
import { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Pencil, Trash } from 'react-bootstrap-icons'
import { useTranslation } from 'react-i18next'
import { Typography } from '../../atoms'
import classes from './DeckTitleDisplay.module.css'

interface DeckTitleDisplayProps {
  deckIdx: number
  name: string
  color: string
  editMode?: boolean
  onEditDeckClicked: (deckIdx: number) => void
  onDeleteDeckClicked: (deckIdx: number) => void
}

const DeckTitleDisplay: FC<DeckTitleDisplayProps> = ({
  deckIdx,
  name,
  color,
  editMode = true,
  onEditDeckClicked,
  onDeleteDeckClicked,
}) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex align-items-center">
      <div
        className={clsx(classes.circle, 'mr-1')}
        style={{
          backgroundColor: color,
          width: 20,
          height: 20,
          marginRight: 10,
          padding: 5,
          border: '0.5px solid rgb(33, 37, 41)',
        }}
      >
      </div>
      <Typography className={classes.deckName} variant="h5">
        {name}
      </Typography>
      {editMode && (
        <Dropdown>
          <Dropdown.Toggle
            variant="default"
            className={classes.deckMenuDropdown}
          />
          <Dropdown.Menu>
            <Dropdown.Item
              eventKey="1"
              onClick={() => onEditDeckClicked(deckIdx)}
            >
              <div className="d-flex align-items-center">
                <Pencil className={classes.icon} />
                {t('common.edit')}
              </div>
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="2"
              onClick={() => onDeleteDeckClicked(deckIdx)}
            >
              <div className="d-flex align-items-center">
                <Trash className={classes.icon} />
                {t('common.delete')}
              </div>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  )
}

export default DeckTitleDisplay
