import { TFunction } from 'react-i18next'

const getTimeDifference = (
  t: TFunction<'translation', undefined>,
  date: Date,
): string => {
  const now = new Date()
  const timeDifference = now.getTime() - date.getTime()

  if (timeDifference < 60000) {
    // Less than a minute
    return t('common.secondsAgo', { time: Math.floor(timeDifference / 1000) })
  } else if (timeDifference < 3600000) {
    // Less than an hour
    return t('common.minutesAgo', { time: Math.floor(timeDifference / 60000) })
  } else if (timeDifference < 86400000) {
    // Less than a day
    return t('common.hoursAgo', { time: Math.floor(timeDifference / 3600000) })
  } else {
    // More than a day
    return t('common.daysAgo', { time: Math.floor(timeDifference / 86400000) })
  }
}

export default getTimeDifference
