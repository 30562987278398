import { FC } from 'react'
import { useRoutes } from 'react-router-dom'
import { Private, Public } from '../../utils/routes'

export interface RoutesProps {
  isLoggedIn: boolean
}

const Routes: FC<RoutesProps> = ({ isLoggedIn }) => {
  const routing = useRoutes(isLoggedIn ? Private : Public)

  return routing
}

export default Routes
