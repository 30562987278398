import { TFunction } from 'react-i18next'
import { Flashcard } from '../api/model'
import { Levels } from '../atoms/Flashcard/Flashcard'

const getFlashcardDifficulty = (
  t: TFunction<'translation', undefined>,
  flashcard: Flashcard,
) => {
  switch (flashcard.level) {
    case Levels.HARD:
      return t('flashcards.study.hard')
    case Levels.MODERATE:
      return t('flashcards.study.moderate')
    case Levels.EASY:
      return t('flashcards.study.easy')
    default:
      return ''
  }
}

export default getFlashcardDifficulty
