import clsx from 'clsx'
import { ReactNode, createElement } from 'react'

export interface TypographyProps {
  children?: ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'label' | 'span'
  className?: string
  transform?: 'lowercase' | 'uppercase' | 'capitalize'
  fontSize?: '1' | '2' | '3' | '4' | '5' | '6'
  fontWeight?: 'bold' | 'bolder' | 'normal' | 'light' | 'lighter'
  textDecoration?: 'underline' | 'line-through' | 'none'
  dataTestId?: string
}

const Typography = ({
  children,
  variant = 'p',
  className,
  transform,
  fontSize,
  fontWeight,
  textDecoration,
  dataTestId,
  ...rest
}: TypographyProps) => {
  const VariantComponent = ({
    ...props
  }: React.HTMLAttributes<HTMLHeadingElement>) =>
    createElement(variant, props, children)

  return (
    <VariantComponent
      className={clsx(
        transform && `text-${transform}`,
        fontSize && `fs-${fontSize}`,
        fontWeight && `fw-${fontWeight}`,
        textDecoration && `text-decoration-${textDecoration}`,
        className,
      )}
      {...rest}
    >
        {children}
    </VariantComponent>
  )
}

export default Typography
