import {
  FC,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from 'react'
import { useHighlightRange } from '../../hooks/useHighlightRange'
import { HighlightExamState } from '../../pages/Exam/Exam'
import checkIfIsChild from '../../utils/checkIfIsChild'

interface State {
  selecting: boolean
  selectedId: string | null
  tagName: string
  attributes: { [key: string]: string }
  styles: { backgroundColor: string; color?: string; display?: string }
}

interface HighlightableProps {
  children: ReactNode
  clearAllHighlights?: boolean
  highlighterColor: string
  questionDescriptionDivId: string
  questionExplanationDivId: string
  canDirectlyHighlight: boolean
  canHighlightOnHighlightMenu: boolean
  highlightMenuShowing: boolean
  highlightExamState: HighlightExamState
  rangeToHighlight?: Range
  onClearAllHighlights?: (ref?: RefObject<HTMLDivElement>) => void
  onHighlight: ({ }: HighlightExamState) => void
  setOnHighlightTrigger: (trigger: boolean) => void
}

const Highlightable: FC<HighlightableProps> = memo(
  ({
    children,
    clearAllHighlights = false,
    highlighterColor,
    questionDescriptionDivId = '',
    questionExplanationDivId = '',
    canDirectlyHighlight,
    highlightMenuShowing,
    canHighlightOnHighlightMenu,
    highlightExamState,
    onHighlight,
    onClearAllHighlights,
    setOnHighlightTrigger,
  }) => {
    const reducer = (state: State, payload: Partial<State>): State => ({
      ...state,
      ...payload,
    })

    const initialState: State = {
      selecting: false,
      selectedId: null,
      tagName: 'span',
      attributes: { class: 'highlighted' },
      styles: { backgroundColor: highlighterColor, color: '#000' },
    }

    const readerRef = useRef<HTMLDivElement>(null)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [rangeHighlighted, setRangeHighlighted] = useState<Range | undefined>(
      undefined,
    )
    // Id to be sent to the backend
    const [highlightedId, setHighlightedId] = useState<string>('')
    const [mousePressed, setMousePressed] = useState(false)
    const [selectionHighlighted, setSelectionHighlighted] = useState<
      Selection | undefined
    >(undefined)

    const { selectedId, tagName, attributes, styles } = state

    const { highlight, removeHighlight, removeAllHighlights } =
      useHighlightRange({
        readerRef,
        tagName,
        attributes,
        styles,
        stateChangeCallback: dispatch,
      })

    const removeAllHighlightsHandler = useCallback(() => {
      removeAllHighlights()
      onClearAllHighlights && onClearAllHighlights(readerRef)
    }, [])

    useEffect(() => {
      if (mousePressed) return

      if (highlightMenuShowing) {
        return
      }

      const highlightRangeProperties = highlight(canDirectlyHighlight)

      setRangeHighlighted(highlightRangeProperties?.range)
      setHighlightedId(highlightRangeProperties?.highlightId ?? '')
    }, [mousePressed])

    useEffect(() => {
      const range = highlightExamState.range

      if (canHighlightOnHighlightMenu && range) {
        highlight(true, range)

        let parentDiv = document.getElementById(questionDescriptionDivId)
        let isQuestionDescription = false
        let isQuestionExplanation = false

        if (!parentDiv) return

        // Checks if the div where the text was selected is the question's description or the question's explanation
        if (!checkIfIsChild(parentDiv, range?.startContainer!)) {
          parentDiv = document.getElementById(questionExplanationDivId)

          if (!parentDiv) return

          isQuestionDescription = false
          if (!checkIfIsChild(parentDiv, range?.startContainer!)) return
          else isQuestionExplanation = true
        } else {
          isQuestionDescription = true
        }

        // The highlighted text, with the trailling newlines
        const highlightedText = range?.toString()!

        const highlightProperties = {
          id: highlightedId,
          delete: false,
          range: rangeHighlighted,
          selectionToHighlight: selectionHighlighted,
          highlightedText,
          newHtmlDivString: parentDiv?.innerHTML,
          isQuestionDescription,
          isQuestionExplanation,
          color: highlighterColor,
        }

        onHighlight(highlightProperties)
        setOnHighlightTrigger(true)

        selectionHighlighted?.removeAllRanges()
      }
    }, [canHighlightOnHighlightMenu])

    // If the user selected any text, it detects if it's from the question description
    // or the question explanation
    useEffect(() => {
      if (rangeHighlighted) {
        let parentDiv = document.getElementById(questionDescriptionDivId)
        let isQuestionDescription = false
        let isQuestionExplanation = false

        if (!parentDiv) return

        // Checks if the div where the text was selected is the question's description or the question's explanation
        if (!checkIfIsChild(parentDiv, rangeHighlighted.startContainer)) {
          parentDiv = document.getElementById(questionExplanationDivId)
          if (!parentDiv) return

          isQuestionDescription = false
          if (!checkIfIsChild(parentDiv, rangeHighlighted.startContainer))
            return
          else isQuestionExplanation = true
        } else {
          isQuestionDescription = true
        }

        // The highlighted text, with the trailling newlines
        const highlightedText = rangeHighlighted.toString()

        const highlightProperties = {
          id: highlightedId,
          delete: false,
          range: rangeHighlighted,
          selectionToHighlight: selectionHighlighted,
          highlightedText,
          newHtmlDivString: parentDiv?.innerHTML,
          isQuestionDescription,
          isQuestionExplanation,
          color: highlighterColor,
        }
        onHighlight(highlightProperties)
        setOnHighlightTrigger(true)
      }
    }, [rangeHighlighted])

    useEffect(() => {
      if (selectedId) {
        const removedHighlightedParentDiv = removeHighlight()
        let parentDiv = document.getElementById(questionDescriptionDivId)
        let isQuestionDescription = true

        if (!parentDiv || !removedHighlightedParentDiv) return

        // Checks if the div where the text was selected is the question's description or the question's explanation
        if (
          !checkIfIsChild(parentDiv, removedHighlightedParentDiv.parentNode!)
        ) {
          parentDiv =
            document.getElementById(questionExplanationDivId) ?? parentDiv
          isQuestionDescription = false
        }

        onHighlight({
          ...highlightExamState,
          delete: true,
          isQuestionDescription,
          isQuestionExplanation: !isQuestionDescription,
          newHtmlDivString: parentDiv.innerHTML,
        })

        setOnHighlightTrigger(true)
      }
    }, [removeHighlight, selectedId])

    useEffect(() => {
      if (clearAllHighlights) removeAllHighlightsHandler()
    }, [clearAllHighlights, removeAllHighlightsHandler])

    // Highlighter color change
    useEffect(() => {
      dispatch({ styles: { ...styles, backgroundColor: highlighterColor } })
    }, [highlighterColor])

    return (
      <div
        id="highlightable"
        ref={readerRef}
        onMouseDown={() => setMousePressed(true)}
        onMouseUp={() => setMousePressed(false)}
      >
        {children}
      </div>
    )
  },
)

export default Highlightable
