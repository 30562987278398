import { forwardRef } from 'react'
import { IconProps } from '../../molecules/NavItem/NavItem'

const ArrowRight = forwardRef<any, IconProps>(
  ({ size, color, ...rest }, ref) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
      version="1.1"
      width={size}
      height={size}
      ref={ref}
      fill={color}
      viewBox="0 0 16 16"
    >
      <path d="m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z" />
    </svg>
  ),
)

export default ArrowRight
